import React from 'react';
import Layout from '../../../layouts/index';

import Success from '../../../components/Success';

const SuccessContainer = () => (
  <Layout slug="Success">
    <Success />
  </Layout>
);

export default SuccessContainer;
